import { SxProps, Theme } from '@mui/material'

import { StyledBox } from './styled'

export type TImage<imageType extends string> = `${string}.${imageType}`

interface IProps {
  webp: TImage<'webp'>
  png: TImage<'png'> | TImage<'jpg'>
  isLazy?: boolean
  alt?: string
  sx?: SxProps<Theme>
}

export function Picture({ png, webp, alt, isLazy, sx }: IProps) {
  return (
    <StyledBox component="picture" sx={sx}>
      <source srcSet={webp} type="image/webp" />
      <img src={png} alt={alt} loading={isLazy ? 'lazy' : 'eager'} />
    </StyledBox>
  )
}
