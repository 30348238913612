import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(() => ({
  display: 'block',
  width: '100%',
  '& img': {
    width: '100%',
    height: '100%',
    aspectRatio: 'auto',
    objectFit: 'contain',
    display: 'block',
  },
}))
